import React, { Fragment, useState, useEffect } from 'react';
import Head from '../component/Head';

const Patentes = () => {
    const [animar, setAnimar] = useState(false);
    const titulosHead = [
		"Bienvenido al Servicio de Patentes Municipales",
		"Consulta información respecto a Clientes con este servicio contratado.",
	];

    //Animacion
	useEffect(() => {
		setAnimar(true);
	}, []);

	return (
		<Fragment>
			<Head contenido={titulosHead} />
			<div className='row'>
				<div className='column'>
					<div className={`row divGenerador ${animar && "animar"}`}>
                    <div className='columns small-12 callout'>
                        <div className='columns small-4'>Puedes:</div>
                        <div className='columns small-8'>Ver Listado de Clientes:</div>
                        &nbsp;
                        <div className='columns small-12'>
                            <div className='columns small-4'>
                                <a
                                    className='success button large'
                                >
                                    Agregar Cliente Nuevo
                                </a>
                            </div>
                            <div className='columns small-2'>
                                <a
                                    className='button large'
                                    id='Vigente'
                                >
                                    Patentes en Trámite
                                </a>
                            </div>
                            <div className='columns small-2'>
                                <a
                                    className='button large'
                                    id='No Vigente'
                                >
                                    Patentes Terminadas
                                </a>
                            </div>
                            <div className='columns small-4'>&nbsp;</div>
                            <div className='columns small-4'>&nbsp;</div>
                        </div>
                    </div>
                        {/* Busqueda Clientes */}
                        <div className='columns small-12 callout'>
                            <div className='columns small-12'>También Puedes:</div>
                            &nbsp;
                            <div className='columns small-12'>
                                <a
                                    className='warning button large expanded'
                                >
                                    Realizar Busqueda de Clientes con Patente
                                </a>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Patentes;